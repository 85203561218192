import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import rowsPerPagePag from '../redux/pagination/selertors';

const usePagination = (fetchData) => {
  const history = useHistory();
  const location = useLocation();

  const perPageSize = useSelector(rowsPerPagePag);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    perPage: perPageSize
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    const page = parseInt(params.get('page'), 10) || 1;
    const perPage = parseInt(params.get('perPage'), 10) || perPageSize;

    setSearch(query);
    setPagination({ pageNumber: page, perPage });
  }, [location.search, perPageSize]);

  const updateUrl = useCallback((query, page, perPage) => {
    const params = new URLSearchParams({
      query,
      page: String(page),
      perPage: String(perPage)
    });

    const newUrl = `${location.pathname}?${params.toString()}`;

    history.push(newUrl);
  }, [history, location.pathname]);

  const updatePagination = useCallback(
    (query, page, perPage) => {
      setSearch(query);
      setPagination({ pageNumber: page, perPage });
      updateUrl(query, page, perPage);
      fetchData(query, page, perPage);
    },
    [updateUrl, fetchData]
  );

  return {
    search,
    pagination,
    updatePagination
  };
};

export default usePagination;
