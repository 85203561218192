import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TargetsTabContent from './TargetsTabContent/TargetsTabContent';

import {
  fetchSessionsCount,
  fetchSessionSessionsCount,
  fetchSessionTargetsCount,
  fetchSingleSessionsCount,
  fetchSingleTargetsCount,
  fetchTargetsCount,
  getSessionTargetsList,
  getSingleTargetsList,
  getTargetsList
} from '../../redux/target/actions';
import {
  sessionCountSelector,
  sessionSessionCountSelector,
  sessionTargetListResultsSelector,
  sessionTargetsCountSelector,
  singleSessionCountSelector,
  singleTargetListResultsSelector,
  singleTargetsCountSelector,
  targetListResultsSelector,
  targetsCountSelector
} from '../../redux/target/selectors';
import { RESET_TARGETS_LISTS } from '../../redux/target/constants';

import style from './Targets.scss';

const Targets = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_TARGETS_LISTS });
  }, [dispatch]);

  const [currentTab, setCurrentTab] = useState('all targets');

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };

  const tabs = [
    {
      name: 'all targets',
      dispatchData: getTargetsList,
      targetsDataSelector: targetListResultsSelector,
      dispatchSessionCount: fetchSessionsCount,
      countSelector: sessionCountSelector,
      dispatchTargetsCount: fetchTargetsCount,
      targetsCountSelector
    },
    {
      name: 'single targets',
      dispatchData: getSingleTargetsList,
      targetsDataSelector: singleTargetListResultsSelector,
      dispatchSessionCount: fetchSingleSessionsCount,
      countSelector: singleSessionCountSelector,
      dispatchTargetsCount: fetchSingleTargetsCount,
      targetsCountSelector: singleTargetsCountSelector
    },
    {
      name: 'sessions',
      dispatchData: getSessionTargetsList,
      targetsDataSelector: sessionTargetListResultsSelector,
      dispatchSessionCount: fetchSessionSessionsCount,
      countSelector: sessionSessionCountSelector,
      dispatchTargetsCount: fetchSessionTargetsCount,
      targetsCountSelector: sessionTargetsCountSelector
    }
  ];

  return (
    <>
      {tabs?.map((t) => (
        <div key={t.name} className={style.tabsContent} style={{ display: t.name === currentTab ? 'flex' : 'none' }}>
          <TargetsTabContent
            tabName={t.name}
            currentTab={currentTab}
            setCurrentTab={handleChangeTab}
            dispatchData={t.dispatchData}
            targetsDataSelector={t.targetsDataSelector}
            dispatchSessionCount={t.dispatchSessionCount}
            countSelector={t.countSelector}
            dispatchTargetsCount={t.dispatchTargetsCount}
            targetsCountSelector={t.targetsCountSelector}
          />
        </div>
      ))}
    </>
  );
};

export default memo(Targets);
