/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  memo, useEffect, useState, useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SearchInput from '../../components/UI/SearchInput';
import Pagination from '../../components/Pagination/Pagination';
import CustomButton from '../../components/UI/CustomButton';
import Heading from '../../components/UI/Heading';
import Popup from '../../components/Popup/Popup';
import NoData from '../../components/NoData/NoData';
import Loader from '../../components/UI/Loader/Loader';
import EventItem from '../../components/EventItem/EventItem';
import FormCreateEvent from '../../components/Forms/FormCreateEvent';

import { fetchEventsCount, fetchEventsList } from '../../redux/events/actions';
import { selectEventsCount, selectEventsList, selectNewEvent } from '../../redux/events/selectors';

import useEventPagination from '../../hooks/usePagination';
import setRowsPerPage from '../../redux/pagination/actions';
import rowsPerPagePag from '../../redux/pagination/selertors';

import style from './Events.scss';

const Events = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const perPageSize = useSelector(rowsPerPagePag);
  const events = useSelector(selectEventsList);
  const eventsCount = useSelector(selectEventsCount);
  const newEvent = useSelector(selectNewEvent);

  const [isPopupEventOpen, setIsPopupEventOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchEvents = useCallback(
    async(query, page, perPage) => {
      try {
        const offset = (page - 1) * perPage;
        await dispatch(fetchEventsList(query, perPage, offset));
        await dispatch(fetchEventsCount(query));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const { search, pagination, updatePagination } = useEventPagination(fetchEvents);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    const page = parseInt(params.get('page'), 10) || 1;
    const perPage = parseInt(params.get('perPage'), 10) || perPageSize;

    if (!params.has('query') || !params.has('page') || !params.has('perPage')) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${query}&page=${page}&perPage=${perPage}`
      });
    } else {
      fetchEvents(query, page, perPage);
    }
  }, [eventsCount, location.search, fetchEvents, history, location.pathname, pagination.perPage, search, updatePagination, perPageSize]);

  useEffect(() => {
    if (events.length === 0 && eventsCount > 0 && pagination.pageNumber !== 1) {
      const prevPage = pagination.pageNumber - 1;
      history.replace({
        pathname: location.pathname,
        search: `?query=${search}&page=${prevPage}&perPage=${pagination.perPage}`
      });
      dispatch(fetchEventsList(search, prevPage, pagination.perPage));
    }
  }, [eventsCount, dispatch, events.length, pagination.pageNumber, pagination.perPage, search, location.pathname, history]);

  const handlePageChange = (page) => {
    updatePagination(search, page, pagination.perPage);
  };

  const handleChangePageSize = (size) => {
    updatePagination(search, 1, size);
    dispatch(setRowsPerPage(size));
  };

  const handleSearch = (value) => {
    const trimmedValue = value.trim();
    updatePagination(trimmedValue, 1, pagination.perPage);
  };

  const onSearchCancel = useCallback(() => {
    fetchEvents('', 1, pagination.perPage);
    updatePagination('', 1, pagination.perPage);
  }, [fetchEvents, pagination.perPage, updatePagination]);

  const openPopupCreateEvent = useCallback(() => setIsPopupEventOpen(true), []);
  const closePopupCreateEvent = useCallback(() => setIsPopupEventOpen(false), []);

  useEffect(() => {
    if (newEvent && isPopupEventOpen) {
      setTimeout(() => {
        history.push(`/events/${newEvent.id}`);
      }, 1000);
      closePopupCreateEvent();
    }
  }, [history, newEvent, isPopupEventOpen, closePopupCreateEvent]);

  return (
    <div className={style.component}>
      <div className={style.titleHolder}>
        <Heading weight={500}>Events</Heading>
        <strong className={style.count}>{eventsCount}</strong>
        <CustomButton
          type="button"
          variant="primary"
          className={style.btn}
          onClick={openPopupCreateEvent}
        >
          Create Event
        </CustomButton>
      </div>
      <Breadcrumb items={[{ name: 'Events', path: 'events' }]} />
      <SearchInput
        value={search}
        style={{ marginTop: '24px', marginBottom: '24px' }}
        onChange={handleSearch}
        onCancelSearch={onSearchCancel}
      />
      <div className={style.holder}>
        {isLoading ? (
          <Loader />
        ) : events.length === 0 ? (
          <NoData>No events</NoData>
        ) : (
          <ul className={style.listHolder}>
            {events.map((e) => (
              <EventItem
                key={e.id}
                id={e.id}
                title={e.title}
                views={e.eventViews || 0}
                date={e.date}
                targets={e.targets}
              />
            ))}
          </ul>
        )}
      </div>
      {eventsCount > 0 ? (
        <Pagination
          total={eventsCount}
          activePage={pagination.pageNumber}
          perPage={pagination.perPage}
          handlePageChange={handlePageChange}
          handleChangePageSize={handleChangePageSize}
        />
      ) : null}
      <Popup open={isPopupEventOpen} handleClose={closePopupCreateEvent}>
        <FormCreateEvent />
      </Popup>
    </div>
  );
};

export default memo(Events);
