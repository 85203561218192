import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { createLogger } from 'redux-logger';
import config from '../config';

import { onUnauthorized } from './auth/actions';

import createApiMiddleware from './middlewares/createApiMiddleware';
import createAuthMiddleware from './middlewares/createAuthMiddleware';
import createErrorsMiddleware from './middlewares/createErrorsMiddleware';

import app from './app/reducer';
import auth from './auth/reducer';
import account from './account/reducer';
import target from './target/reducer';
import scale from './scale/reducer';
import events from './events/reducer';
import pagination from './pagination/reducer';

const onApiError = () => {};

const createRootReducer = () => combineReducers({
  app,
  auth,
  account,
  target,
  scale,
  events,
  pagination
});

const configureStore = (initialState) => {
  const middlewares = [
    thunkMiddleware,
    createApiMiddleware({
      protocol: config.API_PROTOCOL,
      host: config.API_HOST,
      apiPath: config.API_PATH
    }),
    createAuthMiddleware(onUnauthorized),
    createErrorsMiddleware(onApiError)
  ];
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true, duration: true }));
  }
  const composeEnhancers = composeWithDevTools({ name: 'LIVEFRAME_WEBAPP' });
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(
    createRootReducer(),
    initialState,
    enhancers
  );
};

export default configureStore;
