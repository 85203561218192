/* eslint-disable max-len */
import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise, isNotEmptyArray } from '../../../utils';

import InputPassword from '../../UI/InputPassword';
import ErrorMessage from '../../UI/ErrorMessage';
import ButtonWithSpinner from '../../UI/ButtonWithSpinner';

import style from './FormPasswordUpdate.scss';

const DATA = {
  oldPassword: '',
  password: '',
  passwordConfirmation: ''
};

const RULES = {
  oldPassword: {
    required: true
  },
  password: {
    required: true,
    custom: (value) => {
      if (value?.length < 8) {
        return 'Password must be at least 8 characters length';
      }
      return undefined;
    }
  },
  passwordConfirmation: {
    required: true,
    custom: (value, formState) => {
      if (value !== formState.password) {
        return 'Passwords do not match';
      }
      return undefined;
    }
  }
};

const FormPasswordUpdate = ({ onSubmit, onSuccess, onFail }) => {
  const [initialData, setInitialData] = useState(DATA);

  const onSuccessProxy = useCallback(
    (...args) => {
      onSuccess(...args);
      setInitialData({ ...DATA });
    },
    [onSuccess]
  );

  const {
    onFormSubmit, onFieldChange, formState, processing, errors
  } = useFormState(initialData, RULES, onSubmit, onSuccessProxy, onFail);

  const isFormValue = () => formState.oldPassword && formState.password && formState.passwordConfirmation;
  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="oldPassword"
          name="oldPassword"
          placeholder="Old password"
          disabled={processing}
          errors={errors.oldPassword}
          value={formState.oldPassword}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="password"
          name="password"
          placeholder="New password"
          disabled={processing}
          errors={errors.password}
          value={formState.password}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="passwordConfirmation"
          name="passwordConfirmation"
          placeholder="Confirm password"
          disabled={processing}
          errors={errors.passwordConfirmation}
          value={formState.passwordConfirmation}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <ButtonWithSpinner
          type="submit"
          disabled={!isFormValue()}
          processing={processing}
          style={{ width: '100%' }}
        >
          Update
        </ButtonWithSpinner>
      </div>
    </form>
  );
};

FormPasswordUpdate.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

FormPasswordUpdate.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormPasswordUpdate);
