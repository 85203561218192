/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import SearchInput from '../../../components/UI/SearchInput';
import Pagination from '../../../components/Pagination/Pagination';
import CustomButton from '../../../components/UI/CustomButton';
import Heading from '../../../components/UI/Heading';
import { noop } from '../../../utils';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import Popup from '../../../components/Popup/Popup';
import FormCreateTarget from '../../../components/Forms/FormCreateTarget';
import { deleteTarget } from '../../../redux/target/actions';
import TargetItem from '../../../components/TargetItem/TargetItem';
import NoData from '../../../components/NoData/NoData';
import Loader from '../../../components/UI/Loader/Loader';
import { getNewTarget, shouldUpdateSelector } from '../../../redux/target/selectors';
import { RESET_EDIT_POPUP_STATE, RESET_NEW_TARGET } from '../../../redux/target/constants';
import formatDate from '../../../utils/formatDate';

import usePagination from '../../../hooks/usePagination';
import rowsPerPagePag from '../../../redux/pagination/selertors';
import setRowsPerPage from '../../../redux/pagination/actions';

import style from './EventDetailContent.scss';

const EventDetailContent = ({
  currentTab,
  setCurrentTab,
  tabName,
  event,
  dispatchData,
  targetsDataSelector,
  dispatchCount,
  countSelector,
  dispatchTargetsCount
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const perPageSize = useSelector(rowsPerPagePag);
  const shouldUpdate = useSelector(shouldUpdateSelector);
  const targets = useSelector(targetsDataSelector);
  const sessionCount = useSelector(countSelector);
  const newTarget = useSelector(getNewTarget);

  const [isPopupCreateTargetOpen, setIsPopupCreateTargetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    { name: 'all targets' },
    { name: 'single targets' },
    { name: 'sessions' }
  ];

  const getTargets = useCallback(async(query, pageNumber, perPage) => {
    if (!event.id) {
      return;
    }
    const params = {
      eventId: event.id, q: query, limit: perPage, offset: (pageNumber - 1) * perPage
    };
    if (tabName === 'single targets') {
      params.isSingle = true;
    }
    if (tabName === 'sessions') {
      params.isSingle = false;
    }
    dispatch(dispatchData(params));
    dispatch(dispatchCount(params));
    setIsLoading(false);
  }, [dispatch, tabName, dispatchData, event.id, dispatchCount]);

  const { search, pagination, updatePagination } = usePagination(getTargets);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    const page = parseInt(params.get('page'), 10) || 1;
    const perPage = parseInt(params.get('perPage'), 10) || perPageSize;
    const tab = params.get('tab') || 'all';

    if (!params.has('query') || !params.has('page') || !params.has('perPage') || !params.has('tab')) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${query}&page=${page}&perPage=${perPage}&tab=${tab}`
      });
    } else {
      getTargets(query, page, perPage, tab);
    }
  }, [location.search, getTargets, history, location.pathname, shouldUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabFromParams = params.get('tab');
    if (!params.has('tab')) {
      params.set('tab', currentTab.split(' ')[0].toLowerCase());
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    } else {
      let newCurrentTab;
      if (tabFromParams === 'all') {
        newCurrentTab = 'all targets';
      } else if (tabFromParams === 'single') {
        newCurrentTab = 'single targets';
      } else if (tabFromParams === 'sessions') {
        newCurrentTab = 'sessions';
      }
      if (newCurrentTab && newCurrentTab !== currentTab) {
        setCurrentTab(newCurrentTab);
      }
    }
  }, [currentTab, history, location.pathname, location.search, setCurrentTab]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const query = urlParams.get('query') || '';
    const page = parseInt(urlParams.get('page'), 10) || 1;
    const perPage = parseInt(urlParams.get('perPage'), 10) || perPageSize;

    const params = {
      eventId: event.id,
      query,
      page,
      perPage
    };

    if (tabName === 'single targets') {
      params.isSingle = true;
    }
    if (tabName === 'sessions') {
      params.isSingle = false;
    }
    dispatch(dispatchCount(params));
    dispatch(dispatchTargetsCount(params));
    updatePagination(query, page, perPage);
  }, [dispatch, shouldUpdate, dispatchCount, dispatchTargetsCount, tabName, event.id, updatePagination]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenCreatePopup = useCallback(() => {
    dispatch({ type: RESET_NEW_TARGET });
    setIsPopupCreateTargetOpen(true);
  }, [setIsPopupCreateTargetOpen, dispatch]);

  const closePopupCreateTarget = useCallback(() => {
    setIsPopupCreateTargetOpen(false);
    dispatch({ type: RESET_EDIT_POPUP_STATE });
  }, [setIsPopupCreateTargetOpen, dispatch]);

  const onTargetCreateSuccess = useCallback(() => {
    closePopupCreateTarget();
    getTargets(search, pagination.pageNumber, pagination.perPage);
  }, [closePopupCreateTarget, getTargets, pagination.pageNumber, pagination.perPage, search]);

  const handlePageChange = (page) => {
    updatePagination(search, page, pagination.perPage);
  };

  const handleChangePageSize = (size) => {
    updatePagination(search, 1, size);
    dispatch(setRowsPerPage(size));
  };

  const handleSearch = useCallback((searchValue) => {
    const trimmedValue = searchValue.trim();
    updatePagination(trimmedValue, 1, pagination.perPage);
  }, [pagination.perPage, updatePagination]);

  const onSearchCancel = useCallback(() => {
    getTargets('', 1, pagination.perPage);
    updatePagination('', 1, pagination.perPage);
  }, [getTargets, pagination.perPage, updatePagination]);

  const handleUpdateItem = async() => {
    setTimeout(() => {
      getTargets(search, pagination.pageNumber, pagination.perPage);
    }, 1000);
  };

  const handleDeleteItem = async(sessionId) => {
    await dispatch(deleteTarget({ sessionId }));
    if (pagination.pageNumber !== 1 && targets.length <= 1) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${search}&page=${pagination.pageNumber - 1}&perPage=${pagination.perPage}$tab=${currentTab.split(' ')[0]}`
      });
    }
    const params = { eventId: event.id };
    if (tabName === 'single targets') {
      params.isSingle = true;
    }
    if (tabName === 'sessions') {
      params.isSingle = false;
    }
    dispatch(dispatchCount(params));
    dispatch(dispatchTargetsCount(params));
    const newTotalCount = sessionCount - 1;
    const totalPages = Math.ceil(newTotalCount / pagination.perPage);
    const pageNumber = pagination.pageNumber > totalPages ? totalPages : pagination.pageNumber;
    updatePagination(search, pageNumber, pagination.perPage);
    getTargets(search, pagination.pageNumber, pagination.perPage);
  };

  const handleTabClick = (selectedTabName) => {
    const params = new URLSearchParams(location.search);
    params.set('query', '');
    params.set('page', 1);
    params.set('tab', selectedTabName.split(' ')[0].toLowerCase());
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });
    setCurrentTab(selectedTabName);
  };

  return (
    <div className={style.component}>
      <div className={style.titleHolder}>
        <Heading weight={500}>
          {capitalizeFirstLetter(event.title)}
        </Heading>
        {event?.date ? (
          <strong className={style.date}>{formatDate(event.date)}</strong>
        ) : null}
        <CustomButton
          type="button"
          variant="primary"
          className={style.btn}
          onClick={handleOpenCreatePopup}
        >
          Create Target
        </CustomButton>
      </div>
      <Breadcrumb items={[{ name: 'Events', path: '/events' }, { name: event.title, path: '/' }]} />
      <SearchInput
        value={search}
        style={{ marginTop: '24px', marginBottom: '24px' }}
        onChange={handleSearch}
        onCancelSearch={onSearchCancel}
      />
      <ul className={style.tabHolder}>
        {tabs.map((tab) => (
          <li key={tab.name}>
            <button
              className={`${style.tabBtn} ${currentTab === tab.name ? style.active : ''}`}
              onClick={() => handleTabClick(tab.name)}
              type="button"
            >
              {capitalizeFirstLetter(tab.name)}
            </button>
          </li>
        ))}
      </ul>
      <div className={style.holder}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {Array.isArray(targets) && targets.length > 0 ? (
              <ul className={style.listHolder}>
                {targets.map((target) => {
                  if (target && target.sessionId) {
                    const isMatching = newTarget && target.targets?.some((tar) => (
                      newTarget?.targetIds.includes(tar.targetId)
                    ));
                    return (
                      <TargetItem
                        key={target.sessionId}
                        target={target}
                        handleDeleteItem={handleDeleteItem}
                        handleUpdate={handleUpdateItem}
                        isNew={isMatching}
                      />
                    );
                  }
                  return null;
                })}
              </ul>
            ) : (
              <NoData>No targets</NoData>
            )}
          </>
        )}
      </div>
      {sessionCount > 0
        && (
          <Pagination
            total={sessionCount}
            activePage={pagination.pageNumber}
            perPage={pagination.perPage}
            handlePageChange={handlePageChange}
            handleChangePageSize={handleChangePageSize}
          />
        )}
      <Popup
        open={isPopupCreateTargetOpen}
        handleClose={closePopupCreateTarget}
        size="529px"
        closeIconWidth={32}
        closeIconHeight={32}
      >
        <FormCreateTarget onSuccess={onTargetCreateSuccess} defaultEventId={event.id} />
      </Popup>
    </div>
  );
};

EventDetailContent.propTypes = {
  setCurrentTab: PropTypes.func,
  event: PropTypes.object,
  currentTab: PropTypes.string,
  tabName: PropTypes.string,
  dispatchData: PropTypes.func,
  targetsDataSelector: PropTypes.func,
  dispatchCount: PropTypes.func,
  countSelector: PropTypes.func,
  dispatchTargetsCount: PropTypes.func
};

EventDetailContent.defaultProps = {
  event: {},
  currentTab: '',
  setCurrentTab: noop,
  tabName: '',
  dispatchData: noop,
  targetsDataSelector: noop,
  dispatchCount: noop,
  countSelector: noop,
  dispatchTargetsCount: noop
};

export default EventDetailContent;
