/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  memo, useCallback, useEffect, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import Heading from '../../../components/UI/Heading';
import Pagination from '../../../components/Pagination/Pagination';
import SearchInput from '../../../components/UI/SearchInput';
import { noop } from '../../../utils';
import TargetItem from '../../../components/TargetItem/TargetItem';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import { deleteTarget } from '../../../redux/target/actions';
import NoData from '../../../components/NoData/NoData';
import Loader from '../../../components/UI/Loader/Loader';
import { getNewTarget, shouldUpdateSelector } from '../../../redux/target/selectors';
import usePagination from '../../../hooks/usePagination';
import setRowsPerPage from '../../../redux/pagination/actions';
import rowsPerPagePag from '../../../redux/pagination/selertors';

import style from './TargetsTabContent.scss';

const TargetsTabContent = ({
  currentTab,
  setCurrentTab,
  tabName,
  dispatchData,
  targetsDataSelector,
  dispatchSessionCount,
  countSelector,
  dispatchTargetsCount,
  targetsCountSelector
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const shouldUpdate = useSelector(shouldUpdateSelector);
  const targets = useSelector(targetsDataSelector);
  const sessionCount = useSelector(countSelector);
  const targetsCount = useSelector(targetsCountSelector);
  const newTarget = useSelector(getNewTarget);
  const perPageSize = useSelector(rowsPerPagePag);

  const tabs = [
    { name: 'all targets' },
    { name: 'single targets' },
    { name: 'sessions' }
  ];

  const [isLoading, setIsLoading] = useState(true);

  const getTargets = useCallback(async(query, pageNumber, perPage) => {
    const params = {
      q: query, limit: perPage, offset: (pageNumber - 1) * perPage
    };
    if (tabName === 'single targets') {
      params.isSingle = true;
    }
    if (tabName === 'sessions') {
      params.isSingle = false;
    }
    dispatch(dispatchData(params));
    dispatch(dispatchSessionCount(params));
    dispatch(dispatchTargetsCount(params));
    setIsLoading(false);
  }, [dispatch, tabName, dispatchData, dispatchSessionCount, dispatchTargetsCount]);

  const { search, pagination, updatePagination } = usePagination(getTargets);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    const page = parseInt(params.get('page'), 10) || 1;
    const perPage = parseInt(params.get('perPage'), 10) || perPageSize;
    const tab = params.get('tab') || 'all';

    if (!params.has('query') || !params.has('page') || !params.has('perPage') || !params.has('tab')) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${query}&page=${page}&perPage=${perPage}&tab=${tab}`
      });
    } else {
      getTargets(query, page, perPage, tab);
    }
  }, [location.search, getTargets, history, location.pathname, shouldUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabFromParams = params.get('tab');
    if (!params.has('tab')) {
      params.set('tab', currentTab.split(' ')[0].toLowerCase());
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    } else {
      let newCurrentTab;
      if (tabFromParams === 'all') {
        newCurrentTab = 'all targets';
      } else if (tabFromParams === 'single') {
        newCurrentTab = 'single targets';
      } else if (tabFromParams === 'sessions') {
        newCurrentTab = 'sessions';
      }
      if (newCurrentTab && newCurrentTab !== currentTab) {
        setCurrentTab(newCurrentTab);
      }
    }
  }, [currentTab, history, location.pathname, location.search, setCurrentTab]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    const page = parseInt(params.get('page'), 10) || 1;
    const perPage = parseInt(params.get('perPage'), 10) || perPageSize;
    if (tabName === 'single targets') {
      params.isSingle = true;
    }
    if (tabName === 'sessions') {
      params.isSingle = false;
    }
    dispatch(dispatchSessionCount(params));
    dispatch(dispatchTargetsCount(params));
    updatePagination(query, page, perPage);
  }, [dispatch, shouldUpdate, dispatchSessionCount, dispatchTargetsCount, tabName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (page) => {
    updatePagination(search, page, pagination.perPage);
  };

  const handleChangePageSize = (size) => {
    updatePagination(search, 1, size);
    dispatch(setRowsPerPage(size));
  };

  const debouncedSearch = debounce((searchValue) => {
    updatePagination(searchValue.trim(), 1, pagination.perPage);
  }, 3000);

  const handleSearch = useCallback((searchValue) => {
    debouncedSearch(searchValue);
  }, [debouncedSearch]);

  const onSearchCancel = useCallback(() => {
    getTargets('', 1, pagination.perPage);
    updatePagination('', 1, pagination.perPage);
  }, [getTargets, pagination.perPage, updatePagination]);

  const handleUpdateItem = async() => {
    setTimeout(() => {
      getTargets(search, pagination.pageNumber, pagination.perPage);
    }, 1000);
  };

  const handleDeleteItem = async(sessionId) => {
    await dispatch(deleteTarget({ sessionId }));
    const params = {};
    if (tabName === 'single targets') {
      params.isSingle = true;
    }
    if (tabName === 'sessions') {
      params.isSingle = false;
    }
    dispatch(dispatchSessionCount(params));
    dispatch(dispatchTargetsCount(params));
    const newTotalCount = sessionCount - 1;
    const totalPages = Math.ceil(newTotalCount / pagination.perPage);
    const newPageNumber = pagination.pageNumber > totalPages ? totalPages : pagination.pageNumber;
    updatePagination(search, pagination.pageNumber, pagination.perPage);
    getTargets(search, newPageNumber, pagination.perPage);
    if (pagination.pageNumber !== 1 && targets.length <= 1) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${search}&page=${newPageNumber}&perPage=${pagination.perPage}&tab=${currentTab.split(' ')[0]}`
      });
    }
  };

  const handleTabChange = (selectedTabName) => {
    const params = new URLSearchParams(location.search);
    params.set('query', '');
    params.set('page', 1);
    params.set('tab', selectedTabName.split(' ')[0].toLowerCase());
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });
    setCurrentTab(selectedTabName);
  };

  return (
    <div className={style.component}>
      <div className={style.titleHolder}>
        <Heading weight={500}>
          Targets
        </Heading>
        <strong className={style.count}>{targetsCount || 0}</strong>
      </div>
      <SearchInput
        value={search}
        style={{ marginTop: '24px', marginBottom: '24px' }}
        onChange={handleSearch}
        onCancelSearch={onSearchCancel}
      />
      <ul className={style.tabHolder}>
        {tabs?.map((tab) => (
          <li key={tab.name}>
            <button
              // eslint-disable-next-line
              className={tab?.name === currentTab ? style.activeTabBtn + ' ' + style.tabBtn : style.tabBtn}
              onClick={() => handleTabChange(tab?.name)}
              type="button"
            >
              {capitalizeFirstLetter(tab?.name)}
            </button>
          </li>
        ))}
      </ul>
      <div className={style.holder}>
        {isLoading ? (
          <Loader />
        ) : (
          Array.isArray(targets) && targets.length > 0 ? (
            <ul className={style.listHolder}>
              {targets.map((target) => {
                if (target && target.sessionId) {
                  const isMatching = newTarget && target.targets?.some((tar) => (
                    newTarget?.targetIds.includes(tar.targetId)
                  ));
                  return (
                    <TargetItem
                      key={target.sessionId}
                      target={target}
                      handleDeleteItem={handleDeleteItem}
                      handleUpdate={handleUpdateItem}
                      isNew={isMatching}
                    />
                  );
                }
                return null;
              })}
            </ul>
          ) : (
            !isLoading && <NoData>No targets</NoData>
          )
        )}
      </div>
      {sessionCount > 0 ? (
        <Pagination
          total={sessionCount}
          activePage={pagination.pageNumber}
          perPage={pagination.perPage}
          handlePageChange={handlePageChange}
          handleChangePageSize={handleChangePageSize}
        />
      ) : null}
    </div>
  );
};

TargetsTabContent.propTypes = {
  currentTab: PropTypes.string,
  tabName: PropTypes.string,
  setCurrentTab: PropTypes.func,
  dispatchData: PropTypes.func,
  targetsDataSelector: PropTypes.func,
  dispatchSessionCount: PropTypes.func,
  countSelector: PropTypes.func,
  dispatchTargetsCount: PropTypes.func,
  targetsCountSelector: PropTypes.func
};

TargetsTabContent.defaultProps = {
  currentTab: '',
  tabName: '',
  setCurrentTab: noop,
  dispatchData: noop,
  targetsDataSelector: noop,
  dispatchSessionCount: noop,
  countSelector: noop,
  dispatchTargetsCount: noop,
  targetsCountSelector: noop
};

export default memo(TargetsTabContent);
