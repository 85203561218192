import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from 'react';

import { isSuccessAction, noopPromise } from '../utils';
import validate from '../utils/validate';

const EMPTY_VALUES = [undefined, null, ''];

const isEqualValues = (value1, value2) => {
  if (EMPTY_VALUES.indexOf(value1) !== -1 && EMPTY_VALUES.indexOf(value2) !== -1) {
    return true;
  }

  return value1 === value2;
};

// @TODO i think better to use 'useReducer' hook

const useFormState = (
  initialState = {},
  validationRules = {},
  onSubmit = noopPromise,
  onSuccess = noopPromise,
  onFail = noopPromise
) => {
  const [formState, setFormState] = useState(initialState);
  const [processing, setProcessing] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const changed = useMemo(() => {
    const keys = Object.keys(formState);
    const changedFieldName = keys.find((key) => !isEqualValues(formState[key], initialState[key]));
    return !!changedFieldName;
  }, [formState, initialState]);

  const errors = useMemo(() => ({
    ...apiErrors,
    ...validationErrors
  }), [apiErrors, validationErrors]);

  useEffect(() => {
    setFormState(initialState);
  }, [initialState]);

  const setField = useCallback((name, value) => setFormState((state) => ({
    ...state,
    [name]: value
  })), []);

  const setForm = useCallback((data) => setFormState((state) => ({
    ...state,
    ...data
  })), []);

  const onFieldChange = useCallback((value, name) => {
    if (errors && errors[name] && errors[name].length && validationRules[name]) {
      const fieldData = { [name]: value };
      const fieldRules = { [name]: validationRules[name] };
      const validation = validate(fieldData, fieldRules);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: validation.errors[name]
      }));
    }

    if (
      ['password', 'passwordConfirmation'].includes(name)
      && errors?.passwordConfirmation
      && name === 'passwordConfirmation'
      && value === formState.password
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        passwordConfirmation: undefined
      }));
    }

    if (
      ['password', 'passwordConfirmation'].includes(name)
      && errors?.passwordConfirmation
      && name === 'password'
      && formState.passwordConfirmation === value
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        passwordConfirmation: undefined
      }));
    }

    setFormState((state) => ({
      ...state,
      [name]: value
    }));
  }, [errors, validationRules, formState]);

  const onFormSubmit = useCallback((event) => {
    if ((event)) {
      event.preventDefault();
    }

    if (processing) {
      return;
    }

    const validation = validate(formState, validationRules);
    setValidationErrors(validation.errors);

    if (!validation.valid) {
      return;
    }

    setApiErrors(null);
    setProcessing(true);

    onSubmit(formState, changed).then((action) => {
      if (isMounted.current) {
        if (isSuccessAction(action)) {
          onSuccess(action, formState);
        } else {
          setApiErrors(action?.request?.response?.data || null);
          onFail(action);
        }

        setProcessing(false);
      }
    });
  }, [
    processing,
    formState,
    changed,
    validationRules,
    onSubmit,
    onSuccess,
    onFail
  ]);

  return {
    setField,
    setForm,
    onFormSubmit,
    onFieldChange,
    formState,
    changed,
    processing,
    errors
  };
};

export default useFormState;
