import SET_ROWS_PER_PAGE from './constants';

const setRowsPerPage = (rowsPerPage) => {
  localStorage.setItem('rowsPerPage', rowsPerPage);
  return {
    type: SET_ROWS_PER_PAGE,
    payload: rowsPerPage
  };
};

export default setRowsPerPage;
