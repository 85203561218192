import SET_ROWS_PER_PAGE from './constants';

const initialState = {
  rowsPerPagePagination: localStorage.getItem('rowsPerPage')
    ? parseInt(localStorage.getItem('rowsPerPage'), 10)
    : 10
};

const pagination = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPagePagination: action.payload
      };
    default:
      return state;
  }
};

export default pagination;
